// у опции "Выбрать все", если такая есть, value всегда должно быть '*'
export const sortOptions = (options, selectedOptions, value) => {
  const selectAllValue = '*';

  if (
    Array.isArray(selectedOptions) &&
    selectedOptions.length > 0 &&
    options.length > 0
  ) {
    const isSelectOption = options.find(opt => opt[value] === selectAllValue);

    if (isSelectOption) {
      const noSelectedFilteredOptions = options.filter(
        opt =>
          selectedOptions.findIndex(el => el[value] === opt[value]) === -1 &&
          opt[value] !== selectAllValue,
      );

      const selectedFilteredOptions = selectedOptions.filter(
        opt =>
          options.findIndex(el => el[value] === opt[value]) !== -1 &&
          opt[value] !== selectAllValue,
      );

      return [
        isSelectOption,
        ...selectedFilteredOptions,
        ...noSelectedFilteredOptions,
      ];
    } else {
      const noSelectedFilteredOptions = options.filter(
        opt => selectedOptions.findIndex(el => el.id === opt.id) === -1,
      );

      const selectedFilteredOptions = selectedOptions.filter(
        opt => options.findIndex(el => el[value] === opt[value]) !== -1,
      );

      return [...selectedFilteredOptions, ...noSelectedFilteredOptions];
    }
  } else {
    return options;
  }
};
