import axios, { isCancel } from '../axios';

export const getAllOrganizations = async (params, signal) => {
  try {
    const res = await axios.get('v2/organizations/', {
      params,
      signal,
    });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getOrganizationById = async orgId => {
  try {
    const res = await axios.get(`v2/organizations/${orgId}/`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const postNewOrganization = async orgData => {
  try {
    const res = await axios.post('v2/organizations/', orgData);
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateOrganizationById = async (orgId, orgData) => {
  try {
    const res = await axios.put(`v2/organizations/${orgId}/`, orgData);
    return res;
  } catch (error) {
    throw error;
  }
};
