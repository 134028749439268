import PropTypes from 'prop-types';
import './Container.css';

export default function Container({ children }) {
  return <main className="main-container">{children}</main>;
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
};
