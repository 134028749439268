import PropTypes from 'prop-types';
import { components } from 'react-select';
import Caret from '../../assets/icons/Caret';

export default function CustomCaret(props) {
  return (
    <components.DropdownIndicator {...props} className="custom-caret">
      <Caret aria-hidden="true" focusable="false" />
    </components.DropdownIndicator>
  );
}

CustomCaret.propTypes = {
  props: PropTypes.object,
};
