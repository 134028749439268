import axios, { CancelToken, isCancel } from 'axios';
import { getAccessToken } from '../services/localStorage-api';
import { onRefreshToken, statusCodes } from '../utils';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.retry = 3;

const axiosPublic = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

axios.interceptors.request.use(
  async config => {
    const token = getAccessToken();

    if (token) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  error => Promise.reject(error),
);

axios.interceptors.response.use(
  response => response,
  async error => {
    const config = error?.config;
    const message = error?.message?.toLowerCase();

    if (
      error?.response?.status === statusCodes.HTTP_401_UNAUTHORIZED &&
      !config?.sent
    ) {
      config.sent = true;
      const res = await onRefreshToken();

      if (res?.access) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${res.access}`,
        };
      }

      return axios(config);
    }

    if (!config || !config?.retry) {
      return Promise.reject(error);
    }

    if (message.includes('timeout') || message.includes('network error')) {
      config.retry -= 1;
      const delayRetryRequest = new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, config.retryDelay || 2000);
      });
      return delayRetryRequest.then(() => axios(config));
    }

    return Promise.reject(error);
  },
);

export { axiosPublic, CancelToken, isCancel };
export default axios;
