export default function Caret({
  viewBox = '0 0 24 24',
  fill = 'currentColor',
  style = { width: '24px', height: '24px' },
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={style}
      viewBox={viewBox}
    >
      <path d="M7 10l5 5 5-5z"></path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  );
}
