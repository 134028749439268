import { toast } from 'react-toastify';

export const createRequestError = (
  message = 'Что-то пошло не так! Повторите запрос позже.',
) => {
  return toast.error(message, {
    position: 'top-right',
  });
};

export const createRequiredFieldError = () => {
  return toast.warning(
    `Что-то не так! Проверьте, все ли поля заполнены правильно.`,
    {
      position: 'top-right',
    },
  );
};

export const createRequestSaveError = () => {
  return toast.warning('Что-то не так! Попробуйте сохранить позже.', {
    position: 'top-right',
  });
};

export const createFileExtensionError = () => {
  return toast.warning('Неподдерживаемое расширение документа', {
    position: 'top-right',
  });
};
