import { useState, useContext } from 'react';
import { Button, TextInput } from 'react-materialize';
import authContext from '../../context/auth/context';
import './LoginForm.css';

const INITIAL_STATE = {
  username: '',
  password: '',
};

export default function LoginForm() {
  const [username, setUsername] = useState(INITIAL_STATE.username);
  const [password, setPassword] = useState(INITIAL_STATE.password);
  const { onLogIn } = useContext(authContext);

  const inputChangeHandler = e => {
    const { name, value } = e.currentTarget;

    switch (name) {
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;

      default:
        break;
    }
  };

  const submitHandler = async e => {
    e.preventDefault();
    const data = { username, password };
    onLogIn(data);
    setUsername(INITIAL_STATE.username);
    setPassword(INITIAL_STATE.password);
  };

  return (
    <>
      <form className="login-form" onSubmit={submitHandler}>
        <TextInput
          id="login-form-login"
          inputClassName="login-form__login"
          label="Логин"
          required
          name="username"
          value={username}
          onChange={inputChangeHandler}
        />

        <TextInput
          id="login-form-password"
          inputClassName="login-form__password"
          label="Пароль"
          required
          password
          name="password"
          value={password}
          onChange={inputChangeHandler}
        />
        <Button
          className="login-btn indigo"
          type="submit"
          node="button"
          waves="light"
        >
          Войти
        </Button>
      </form>
    </>
  );
}
