import { useEffect } from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';

export default function ValueContainer({ children, ...props }) {
  const allOption = {
    label: 'Выбрать все',
    value: '*',
  };
  const currentValues = props.getValue();
  const styles = props.getStyles('valueContainer', props);

  const containerRef = props.selectProps.containerRef;

  useEffect(() => {
    const { menuIsOpen } = props.selectProps;

    const isContainerOverflow =
      containerRef?.current?.scrollWidth > containerRef?.current?.clientWidth;

    if (containerRef?.current) {
      if (menuIsOpen && isContainerOverflow) {
        containerRef.current.scrollTo(
          containerRef?.current?.scrollWidth + 1,
          0,
        );
      } else {
        containerRef.current.scrollLeft = 0;
      }
    }
  }, [containerRef, props.selectProps]);

  let toBeRendered = children;

  if (
    currentValues.some(
      el => el[props.selectProps?.optionKey?.value] === allOption.value,
    )
  ) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      <div ref={containerRef} style={styles}>
        {toBeRendered}
      </div>
    </components.ValueContainer>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  props: PropTypes.object,
};
