import { Row, Col } from 'react-materialize';
import './CustomLoader.css';

export default function CustomLoader() {
  return (
    <div className="table-loader-overlay">
      <Row className="loader__wrapper">
        <Col s={12}>
          <div className="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
