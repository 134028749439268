import { components } from 'react-select';
import PropTypes from 'prop-types';

export default function MultiValue({ children, ...props }) {
  return (
    <components.MultiValue {...props}>{children + ';'}</components.MultiValue>
  );
}

MultiValue.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  props: PropTypes.object,
};
