import { components } from 'react-select';
import PropTypes from 'prop-types';
import { Checkbox } from 'react-materialize';

export default function CustomOptions({ children, ...props }) {
  return (
    <>
      <components.Option {...props}>
        {props.isMulti ? (
          <Checkbox
            id={'CheckboxCustomOptions-' + props.value}
            label={children}
            value={children}
            checked={props.isSelected}
            onChange={() => null}
          />
        ) : (
          children
        )}
      </components.Option>
    </>
  );
}

CustomOptions.propTypes = {
  children: PropTypes.string,
  props: PropTypes.object,
};
