import React from 'react';
import PropTypes from 'prop-types';
import './CustomBadge.css';

const CustomBadge = ({ content }) => {
  return <span className="custom-badge">{content}</span>;
};

CustomBadge.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CustomBadge;
