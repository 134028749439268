import { Row } from 'react-materialize';
import './ForbiddenPlate.css';

export default function ForbiddenPlateMarkup() {
  return (
    <Row className="plate__wrapper">
      <h3>Доступ запрещен</h3>
      <h4>
        Код ошибки: <span>403</span>
      </h4>
      <h5>
        Для получения доступа напишите запрос на{' '}
        <a href="mailto:medexp@mainsgroup.ru">medexp@mainsgroup.ru</a>
      </h5>
    </Row>
  );
}
