import mem from 'mem';
import { refreshAuthenticated } from '../services/auth-api';
import {
  setTokens,
  getRefreshToken,
  removeStore,
} from '../services/localStorage-api';

const onRefreshToken = async () => {
  const refreshToken = getRefreshToken();

  try {
    const res = await refreshAuthenticated({ refresh: refreshToken });

    if (!res?.data?.access) {
      removeStore();
    } else {
      setTokens(res.data.access, refreshToken);
    }

    return res;
  } catch (error) {
    removeStore();
  }
};

const maxAge = 20000;

export default mem(onRefreshToken, {
  maxAge,
});
