import axios, { axiosPublic } from '../axios';

export const getAuthenticated = async data => {
  try {
    const res = await axios.post('v2/token/', data, {
      contentType: 'application/x-www-form-urlencoded',
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const refreshAuthenticated = async data => {
  try {
    const res = await axiosPublic.post('v2/token/refresh/', data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const logoutCurrentUser = async data => {
  try {
    const res = await axios.post('v2/token/blacklist/', data);
    return res;
  } catch (error) {
    throw error;
  }
};

export async function getCurrentUser() {
  try {
    const res = await axios.get('v2/users/self/');
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getLogo() {
  try {
    const res = await axios.get('v2/media/logo_of_related_organization/');
    return res;
  } catch (error) {
    throw error;
  }
}
