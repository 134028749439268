import { useContext } from 'react';
import { Col, Icon } from 'react-materialize';
import { getInstructionFile } from '../../services/catalogs-api';
import { saveFile, createRequestError } from '../../utils';
import authContext from '../../context/auth/context';
import './Footer.css';

export default function Footer() {
  const { isPaginatedTable } = useContext(authContext);

  const getFooterClasses = () => {
    let className = 'footer';
    if (isPaginatedTable) {
      className += ' moved';
    }

    return className;
  };

  const handleDownloadInstructionFile = async () => {
    try {
      const data = await getInstructionFile();

      if (data) {
        saveFile(data.data, 'MAINSLAB_Поиск_завышений_Инструкция.pdf');
      }
    } catch (error) {
      createRequestError();

      console.log(error.message);
    }
  };

  return (
    <footer className={getFooterClasses()}>
      <Col className="footer_item">
        &#169; 2020-{new Date().getFullYear()} MainsLab
      </Col>
      <Col className="footer_item">
        Инструкция:{' '}
        <Icon
          role="button"
          className="instruction__icon"
          title="Скачать инструкцию"
          onClick={handleDownloadInstructionFile}
        >
          menu_book
        </Icon>
      </Col>
      <Col className="footer_item">
        Техподдержка:{' '}
        <a href="mailto: medexp@mainsgroup.ru">medexp@mainsgroup.ru</a>
      </Col>
      <Col className="footer_item">
        <Icon className="footer__icon">check_circle_outline</Icon> № в реестре
        российского ПО:
        <a href="https://reestr.digital.gov.ru/reestr/2307923/" target="blank">
          2023610936
        </a>
      </Col>
    </footer>
  );
}
