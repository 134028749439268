const validatePassword = (password1, password2) => {
  if (password1.length < 8) {
    return 'Длина пароля должна быть не менее 8 символов';
  } else if (/^[0-9]+$/g.test(password1)) {
    return 'Пароль не должен состоять только из цифр';
  } else if (password1 === password2 && password1 !== '') {
    return '';
  } else {
    return 'Пароли не совпадают';
  }
};
export default validatePassword;
