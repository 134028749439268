import {
  statusCodes,
  checkResponseUploadFile,
  createRequiredFieldError,
  createRequestError,
} from './';

export default function getUploadFileError(error) {
  if (error.response.status === statusCodes.HTTP_400_BAD_REQUEST) {
    const responseMessage = checkResponseUploadFile(error.response.data);

    if (responseMessage.length > 0) {
      return responseMessage;
    } else {
      createRequiredFieldError();
    }
  } else {
    createRequestError();
  }
}
