const TOKEN = 'token_me';
const REFRESH = 'refresh_me';

export const setTokens = (token, refreshToken) => {
  localStorage.setItem(TOKEN, token);
  localStorage.setItem(REFRESH, refreshToken);
};

export const getAccessToken = () => {
  return localStorage.getItem(TOKEN);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH);
};

export const removeStore = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(REFRESH);
};
