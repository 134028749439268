import { useContext, useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Button, Icon } from 'react-materialize';
import * as Popover from '@radix-ui/react-popover';
import parse from 'html-react-parser';
import CustomBadge from '../CustomBadge/CustomBadge';
import CustomSelect from '../CustomSelect/CustomSelect';

import logo from '../../assets/ML_logo_medexp.png';
import authContext from '../../context/auth/context';
import {
  checkSomePermissions,
  createRequestError,
  statusCodes,
} from '../../utils';
import { getAllOrganizations } from '../../services/orgs-api';
import { updateUserById } from '../../services/users-api';
import config from '../../config/config.json';
import './Header.css';
import { routes } from '../../helpers/constants';

export default function Header() {
  const [isLoading, setIsLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState('');

  const {
    onLogOut,
    isLoggedIn,
    currentUser,
    perms,
    currentLogo,
    unreadMsgCount,
    currentCustomer,
  } = useContext(authContext);

  const navigate = useNavigate();

  const username =
    currentUser?.user?.first_name.charAt(0) +
    '. ' +
    currentUser?.user?.last_name;
  const isSupportServiceAdmin = perms?.includes(
    'complaints.admin_support_service',
  );

  useEffect(() => {
    if (currentCustomer) {
      setSelectedOrganization(currentCustomer);
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (!isSupportServiceAdmin) {
      return;
    }

    let isCleanup = false;
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const orgs = await getAllOrganizations({
          page_size: config.max_entity_count,
        });

        if (!isCleanup) {
          setOrganizations(orgs.data.results);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();

    return () => {
      isCleanup = true;
      setIsLoading(prev => ({ ...prev, options: false }));
    };
  }, [isSupportServiceAdmin]);

  const handleCustomSelect = async value => {
    try {
      setSelectedOrganization(value);
      const newData = { user: currentUser.user, organization: value.name };
      const result = await updateUserById(currentUser.id, newData);
      if (result.status === statusCodes.HTTP_200_OK) {
        window.location.reload();
      }
    } catch (error) {
      setSelectedOrganization(currentCustomer);
      createRequestError();
      console.log(error.message);
    }
  };

  const complaintPath = !isSupportServiceAdmin ? 'personal' : 'all';

  const catalogPath = perms?.includes('mainapp.view_lpu')
    ? 'lpu'
    : perms?.includes('mainapp.view_division')
    ? 'cities'
    : perms?.includes('mainapp.view_normativeactrow')
    ? 'normative_acts'
    : perms?.includes('mainapp.view_normativeactanalysis')
    ? 'na_analysis'
    : '';

  return (
    <header className="header">
      <Navbar
        className="header__navbar"
        alignLinks="right"
        brand={
          <div className="logo__wrapper" onClick={() => navigate('/')}>
            <div className="ml-logo">
              <img src={logo} alt="Logo" className="logo__img" />
            </div>
            <div className="client-logo">
              {currentLogo ? parse(currentLogo) : ''}
            </div>
          </div>
        }
        id="mobile-nav"
        menuIcon={<Icon>menu</Icon>}
        options={{
          draggable: true,
          edge: 'left',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          outDuration: 200,
          preventScrolling: true,
        }}
      >
        {/* if fist Link functional ready - replace div with NavLink and add View in App */}
        {isLoggedIn && (
          <div className="header-hub__disabled">
            <Icon left>construction</Icon>
            <span>Конструктор</span>
          </div>
        )}
        {perms?.includes('tasks.view_task') && (
          <NavLink
            to={routes.DEFAULT_TASK}
            className="header-link"
            reloadDocument
          >
            <Icon left>home</Icon>
            <span>Задания</span>
          </NavLink>
        )}
        {perms?.includes('mainapp.view_clientrule') && (
          <NavLink to={'/rules'} className="header-link" reloadDocument>
            <Icon left>topic</Icon>
            <span>Правила</span>
          </NavLink>
        )}
        {checkSomePermissions(
          [
            'mainapp.view_division',
            'mainapp.view_lpu',
            'mainapp.view_normativeactrow',
            'mainapp.view_normativeactanalysis',
          ],
          perms,
        ) && (
          <NavLink
            to={'/catalogs/' + catalogPath}
            className="header-link"
            reloadDocument
          >
            <Icon left>import_contacts</Icon>
            <span>Справочники</span>
          </NavLink>
        )}
        {/* Обращения */}
        {perms && (
          <NavLink
            to={'/complaints/' + complaintPath}
            className="header-link complaints"
            title="Обращения"
            reloadDocument
          >
            <Icon>question_answer</Icon>
            {isSupportServiceAdmin
              ? unreadMsgCount?.all > 0 && (
                  <CustomBadge content={unreadMsgCount.all} />
                )
              : unreadMsgCount?.personal > 0 && (
                  <CustomBadge content={unreadMsgCount.personal} />
                )}
          </NavLink>
        )}
        {checkSomePermissions(
          ['mainapp.view_rule', 'mainapp.view_specificrule'],
          perms,
        ) && (
          <NavLink
            to={'/system'}
            className="header-link"
            title="Правила"
            reloadDocument
          >
            <Icon>traffic</Icon>
          </NavLink>
        )}
        {perms?.includes('mainapp.change_profile') && (
          <NavLink
            to={'/users'}
            className="header-link"
            title="Пользователи"
            reloadDocument
          >
            <Icon>supervisor_account</Icon>
          </NavLink>
        )}
        {perms?.includes('mainapp.view_clientorganization') && (
          <NavLink
            to={'/organizations'}
            className="header-link"
            title="Заказчики"
            reloadDocument
          >
            <Icon>account_balance</Icon>
          </NavLink>
        )}
        {perms?.includes('mainapp.view_unloading') && (
          <NavLink
            to={'/unloading'}
            className="header-link"
            title="Выгрузки"
            reloadDocument
          >
            <Icon>cloud_download</Icon>
          </NavLink>
        )}
        {currentUser &&
          currentCustomer &&
          (!isSupportServiceAdmin ? (
            <div className="header-hub__plate">
              <Icon left>face</Icon>
              <span>{username}</span>
            </div>
          ) : (
            <Popover.Root>
              <Popover.Trigger
                node="button"
                waves="light"
                className="btn indigo header-btn user-btn"
                title="Изменить заказчика"
              >
                <Icon left>face</Icon>
                <span>{username}</span>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content className="popover-content header-popover-content">
                  <CustomSelect
                    id="HeaderSelect-1"
                    menuIsOpen
                    isLoading={isLoading}
                    name="organization"
                    options={organizations}
                    value={selectedOrganization}
                    onChange={handleCustomSelect}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.name}
                    components={{
                      DropdownIndicator: null,
                      IndicatorSeparator: null,
                    }}
                  />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          ))}
        {isLoggedIn && (
          <Button
            node="button"
            waves="light"
            className="indigo header-btn"
            onClick={onLogOut}
            title="Выйти"
          >
            <Icon className="small-icon exit-icon">logout</Icon>
          </Button>
        )}
      </Navbar>
    </header>
  );
}
