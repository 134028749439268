import { task } from '../utils';

export const complaintStatuses = {
  OPEN: 'Открыто',
  PROCESS: 'В работе',
  CLARIFY: 'На уточнении',
  CLOSE: 'Закрыто',
};

export const routes = {
  DEFAULT_TASK: `/tasks?${task.HISTORICAL}=false`,
};
