import axios, { isCancel } from '../axios';

export const getAllUsers = async (params, signal) => {
  try {
    const res = await axios.get(`v2/users/`, {
      params,
      signal,
    });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getUserById = async userId => {
  try {
    const res = await axios.get(`v2/users/${userId}/`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const postNewUser = async userData => {
  try {
    const res = await axios.post('v2/users/', userData);
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateUserById = async (userId, userData) => {
  try {
    const res = await axios.put(`v2/users/${userId}/`, userData);
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateUserPassword = async (userId, userData) => {
  try {
    const res = await axios.post(
      `v2/users/${userId}/update_password/`,
      userData,
    );
    return res;
  } catch (error) {
    throw error;
  }
};
