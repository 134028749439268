export const documentTypes = [
  'pdf',
  'txt',
  'doc',
  'docx',
  'docm',
  'xls',
  'xlsx',
  'xlsm',
  'ppt',
  'pptx',
  'pptm',
  'html',
  'htm',
  'xps',
  'odt',
  'fodt',
  'ods',
  'fods',
  'odp',
  'fodp',
  'csv',
  'xml',
  'pages',
  'json',
  'vcf',
  'vcard',
  'eml',
  'msg',
  'jpg',
  'jpeg',
  'jpe',
  'jif',
  'jfif',
  'jfi',
  'png',
  'gif',
  'svg',
  'svgz',
  'webp',
  'heif',
  'heifs',
  'heic',
  'heics',
  'dds',
  'jxr',
  'hdp',
  'wdp',
  'psd',
  'tiff',
  'tif',
  'eps',
  'epsf',
  'epsi',
  'bmp',
  'dib',
  'avif',
  'ai',
  'cdr',
  'cdt',
  'dwg',
  'dws',
  'dwt',
  'ico',
];
