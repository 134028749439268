import { useContext } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import ForbiddenPlate from '../ForbiddenPlate/ForbiddenPlate';
import CustomLoader from '../CustomLoader/CustomLoader';
import authContext from '../../context/auth/context';

export default function PrivateRoute({
  redirectTo = '/login',
  children,
  isAllowed = true,
}) {
  const { isLoggedIn, perms } = useContext(authContext);

  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to={redirectTo} state={{ from: location }} />;
  }

  if (!perms) {
    return <CustomLoader />;
  }
  return isAllowed ? (
    children ? (
      children
    ) : (
      <Outlet />
    )
  ) : (
    <ForbiddenPlate perms={perms} />
  );
}

PrivateRoute.propTypes = {
  redirectTo: PropTypes.string,
  children: PropTypes.node,
  isAllowed: PropTypes.bool,
};
