import { useContext } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import authContext from '../../context/auth/context';

export default function PublicRoute({ redirectTo, children }) {
  const { isLoggedIn } = useContext(authContext);
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || redirectTo;

  if (isLoggedIn) {
    return <Navigate to={fromPage} state={{ from: location }} />;
  }

  return children ? children : <Outlet />;
}

PublicRoute.propTypes = {
  redirectTo: PropTypes.string,
  children: PropTypes.node,
};
