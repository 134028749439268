const saveFile = (data, filename) => {
  const url = window.URL.createObjectURL(data);

  const tmpLink = document.createElement('a');
  tmpLink.href = url;
  tmpLink.setAttribute('download', filename);
  document.body.appendChild(tmpLink);
  tmpLink.click();
  document.body.removeChild(tmpLink);

  URL.revokeObjectURL(url);
};

export default saveFile;
