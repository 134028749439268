import axios, { isCancel } from '../axios';

export const createComplaints = async data => {
  try {
    const res = await axios.post(
      'v2/support_service/complaints/create/',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return res;
  } catch (error) {
    throw error;
  }
};

export const getComplaintsList = async (params, signal) => {
  try {
    const res = await axios.get('v2/support_service/complaints/list/', {
      params,
      signal,
    });

    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getComplaintsTypes = async () => {
  try {
    const res = await axios.get('v2/support_service/complaints/types/list/');

    return res;
  } catch (error) {
    throw error;
  }
};

export const getComplaintStatuses = async () => {
  try {
    const res = await axios.get('v2/support_service/complaints/statuses/list/');

    return res;
  } catch (error) {
    throw error;
  }
};

export const getComplaintById = async (complaintId, signal) => {
  try {
    const res = await axios.get(
      `v2/support_service/complaints/${complaintId}`,
      {
        signal,
      },
    );

    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const postComplaintFile = async data => {
  try {
    const res = await axios.postForm('v2/support_service/messages/add_file/', {
      ...data,
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export const createMessage = async data => {
  try {
    const res = await axios.post('v2/support_service/messages/create/', {
      ...data,
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export const getMessagesList = async (params, signal) => {
  try {
    const res = await axios.get('v2/support_service/messages/list/', {
      params,
      signal,
    });

    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getCountUnreadMessages = async params => {
  try {
    const res = await axios.get(
      'v2/support_service/messages/self_get_count_unread_messages/',
      { params },
    );

    return res;
  } catch (error) {
    throw error;
  }
};

export const updateComplaintStatus = async (data, id) => {
  try {
    const res = await axios.put(
      `v2/support_service/complaints/update/${id}`,
      data,
    );

    return res;
  } catch (error) {
    throw error;
  }
};
