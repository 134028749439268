import { Row, Col } from 'react-materialize';
import LoginForm from '../components/LoginForm/LoginForm';
import './styles/LoginView.css';

export default function LoginView() {
  return (
    <main>
      <Row className="login-main">
        <Col s={4} className="login-form__column">
          <h3 className="login-form__heading">Вход в систему</h3>
          <LoginForm />
        </Col>
        <Col s={8} className="login-bg">
          <h1 className="login-main__heading">MAINS LAB</h1>
          <p className="login-main__text">
            Система поддержки внутренней экспертизы
          </p>
        </Col>
      </Row>
    </main>
  );
}
