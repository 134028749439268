export const checkResponse = (data, results = []) => {
  const message = results;

  Object.keys(data).forEach(key => {
    if (
      key === 'user' ||
      key === 'password' ||
      key === 'name' ||
      key === 'username' ||
      key === 'new_password'
    ) {
      const value = data[key];
      if (Array.isArray(value)) {
        message.push(...value);
      } else if (!Array.isArray(value) && typeof value === 'object') {
        checkResponse(value, message);
      }
    }
  });

  return message;
};

export const checkCommonResponse = (data, results = []) => {
  const message = results;

  Object.keys(data).forEach(key => {
    const value = data[key];
    if (Array.isArray(value)) {
      message.push(...value);
    } else if (!Array.isArray(value) && typeof value === 'object') {
      checkResponse(value, message);
    } else if (!Array.isArray(value) && typeof value === 'string') {
      message.push(value);
    }
  });

  return message?.join('\n');
};
