import { useLocation } from 'react-router-dom';
import ForbiddenPlateMarkup from './ForbiddenPlateMarkup';
import Container from '../Container/Container';
import { checkSomePermissions } from '../../utils';

export default function ForbiddenPlate({ perms }) {
  const { pathname } = useLocation();

  const isOffset = ['catalogs'].some(el => pathname.includes(el));
  const isCatalogPerms = checkSomePermissions(
    [
      'mainapp.view_division',
      'mainapp.view_lpu',
      'mainapp.view_normativeactrow',
      'mainapp.view_normativeactanalysis',
    ],
    perms,
  );

  return isOffset && isCatalogPerms ? (
    <ForbiddenPlateMarkup />
  ) : (
    <Container>
      <ForbiddenPlateMarkup />
    </Container>
  );
}
